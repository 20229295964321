<template>
    <div class="container rtl">
        <div class="row my-3">
            <div class="col">
                <h4>وەسڵە نەگەڕاوەکان</h4>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <table  class="table table-striped" >
                    <thead class="thead-dark">
                        <tr>
                            <!-- <th scope="col">بەروار</th> -->
                            <th scope="col">ژ.پسوڵە</th>
                            <th scope="col">بەشداربوو</th>
                            <!-- <th scope="col">بڕی دینار</th> -->
                            <!-- <th scope="col">دۆخی پسوڵە</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in invoice_list" :key="index">
                            
                            <!-- <td>{{item.invoice_date}}</td> -->
                            <td>
                                <router-link :to="'/invoice/'+item.invoice_id">
                                {{item.invoice_number}}</router-link>
                                
                            </td>
                            <td>{{item.customer_name}}
                                <a class="btn btn-success mx-1 my-1" :href="'tel:'+item.customer_phone" role="button">
                                    <i class="fa fa-mobile" aria-hidden="true"></i>
                                </a>
                                <a  class="btn btn-info mx-1 my-1" target="_blank" :href="'https://www.google.com/maps/dir/?api=1&destination=' + item.customer_latitude + ',' + item.customer_longitude" role="button">
                                    <i class="fa fa-location-arrow" aria-hidden="true"></i>
                                </a>
                            </td>
                            <!-- <td>{{$service.toIQD(item.total_iqd)}}</td> -->
                            <!-- <td>
                                <button  :class="` btn-sm btn btn-${checkStatus(item.invoice_status).color}`">
                                    {{checkStatus(item.invoice_status).text}}
                                </button>
                            </td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    import moment from 'moment'
    export default {
        data() {
            return {
                selected_invoice: {},
                invoice: {
                    store_id: 0
                },
                invoice_list : [],
                resetable: false,
                msg: {
                    color: '',
                    model: false,
                    text: ''
                },
                filter:{
                    from : moment().format('YYYY-MM-DD'),
                    to: moment().format('YYYY-MM-DD'),
                    customer_id:'',
                }
            }
        },
        computed: {
            invoice_status_list(){
                return this.$store.getters.invoice_status_list
            },
            invoices() {
                return this.$store.getters.invoice
            },
            customer() {
                return this.$store.getters.customer
            },
            stores() {
                return this.$store.getters.store
            },
            auth() {
                return this.$store.getters.auth
            },
            users() {
                return this.$store.getters.users
            }
        },
        methods: {
            checkStatus(s){
                let list = this.invoice_status_list.filter(f =>{
                    return f.value == s
                })

                return list[0]
            },
            getinvoice() {
                this.invoice_list = []
                $('#dataTable').DataTable().destroy();
                axios.post('invoice/wait.php')
                    .then(r => {
                        if (r.data.status == 'ok') {
                            this.invoice_list = r.data.data
                            this.$nextTick(() => {
                                $('#dataTable').DataTable();
                            })
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            addinvoice() {
                let params = this.invoice
                axios.post('invoice/create.php', params)
                    .then(r => {
                        if (r.data.status == 'ok') {
                            if (this.resetable) {
                                this.invoice_list = [];
                            }
                            this.getinvoice()
                            this.msg = {
                                model: true,
                                text: 'پسوڵەی بەشداربوو زیادکرا',
                                color: 'success'
                            }
                            this.$toast(this.msg.text);
                            $('#addModal').modal('hide')
                        } else {
                            alert(r.data.message);
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            deleteinvoice() {
                let params = {
                    invoice_id: this.selected_invoice.invoice_id
                }
                axios.post('invoice/delete.php', params)
                    .then(r => {
                        if (r.data.status == 'ok') {
                            $('#deleteModal').modal('hide')
                            this.getinvoice()
                            this.msg = {
                                model: true,
                                text: ' پسوڵەی بەشداربوو سڕایەوە',
                                color: 'danger'
                            }
                            this.$toast(this.msg.text);
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            updateinvoice() {
                let params = this.selected_invoice
                axios.post('invoice/update.php', params)
                    .then(r => {
                        if (r.data.status == 'ok') {
                            this.getinvoice()
                            $('#updateModal').modal('hide')
                            this.msg = {
                                model: true,
                                text: 'پسوڵەی بەشداربوو نوێکرایەوە',
                                color: 'success'
                            }
                            this.$toast(this.msg.text);
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            openinvoice(item, type) {
                this.selected_invoice = item
                if (type == 'remove') {
                    $('#deleteModal').modal('show')
                } else {
                    $('#updateModal').modal('show')
                }
            }
        },
        mounted() {
            $('#deleteModal').modal('hide')
            $('#updateModal').modal('hide')
            $('#dataTable').DataTable();
            this.getinvoice()
            this.invoice.user_id = this.auth.user.user_id
            this.invoice.store_id = this.auth.user.store_id
        },
    }
</script>